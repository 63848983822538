import * as React from 'react'
import "../styles/form-dl.css"

const FormSentContactPage = () => {

  return (
    <div className="formDl">
      <div className="formDl__wrapper">

        <h2 className="formDl__heading">フォームが送信されました</h2>
        <div>
          <p className="formDl__text --center">お問い合わせいただきありがとうございます！<br />
            弊社より1~2営業日以内にご返信させていただきます。<br /><br />
          </p>


          <a className='formDl__button' href="https://jpholic.jp/ithojo/">TOPにもどる</a>
        </div>
      </div>
    </div >
  )
}
export default FormSentContactPage